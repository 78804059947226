import React, { useState } from "react"
import CustomButton from "./button"
import Banner from "./banner"
import BlogList from "./blogList"
import ConnectIcon from "../assets/connect-icon.svg"

import "./wpBlog.css"
import { navigate } from "gatsby"

const WpBlog = ({ blogPreviews }) => {
  const [amount, setAmount] = useState(12)

  return (
    <div className="blog-list">
      <Banner />
      <div className="blog-list-body">
        <div className="blog-list-body-header">
          <h1>
            <strong>
              The home
              <br />
              loan
            </strong>{" "}
            handbook
          </h1>
          <p>
            Information, insights and support from industry-leading home loan
            experts.
          </p>
        </div>
        <BlogList blogPreviews={blogPreviews} amount={amount} />
        {blogPreviews.length > amount && (
          <div className="blog-button">
            <CustomButton
              variant="secondary"
              className="blog-button-content"
              onClick={() => setAmount(amount + 6)}
            >
              View more
            </CustomButton>
          </div>
        )}

        <div className="blog-banner">
          <div className="blog-banner-circle-image">
            <ConnectIcon className="blog-banner-icon" />
          </div>
          <div className="blog-banner-header">Connect with Compare n Save</div>
          <div className="blog-button">
            <CustomButton
              variant="secondary"
              className="blog-button-content"
              onClick={() => navigate("/contact")}
            >
              Request a call back
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WpBlog
